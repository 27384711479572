import keywords from '@/utils/options/keywords';

const model = {
  title: '',
  description: '',
  publication_date: '',
  link: '',
  location: '',
  keywords: '',
};

const form = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    placeholder: 'Title',
    component: 'el-input',
    required: true,
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    component: 'el-input',
    required: true,
  },
  {
    type: 'date',
    name: 'publication_date',
    label: 'Publication date',
    placeholder: 'Publication date',
    component: 'el-date-picker',
    required: true,
  },
  {
    type: 'text',
    name: 'link',
    label: 'Link',
    placeholder: 'Link',
    component: 'el-input',
    required: true,
  },
  {
    type: 'select',
    name: 'location',
    label: 'Geographic scope',
    placeholder: 'Geographic scope',
    component: 'el-select',
    required: true,
    options: [
      {
        label: 'France',
        value: 'france',
      },
      {
        label: 'Europe',
        value: 'europe',
      },
      {
        label: 'World',
        value: 'world',
      },
    ],
  },
  {
    type: 'select',
    name: 'keywords',
    label: 'Keywords',
    placeholder: 'Keywords',
    component: 'el-select',
    multiple: true,
    required: true,
    options: keywords,
  },
];

const rules = {
  title: [
    {
      type: 'required',
      message: 'Title is required.',
    },
    {
      type: 'string',
      message: 'Title must be a string.',
    },
    {
      type: 'minLength',
      minLength: 3,
      message: 'Title must be at least 3 characters.',
    },
    {
      type: 'maxLength',
      maxLength: 100,
      message: 'Title must be less than 100 characters.',
    },
  ],
  description: [
    {
      type: 'required',
      message: 'Description is required.',
    },
    {
      type: 'minLength',
      minLength: 50,
      message: 'Description must be at least 50 characters.',
    },
    {
      type: 'maxLength',
      maxLength: 500,
      message: 'Description must be less than 500 characters.',
    },
  ],
  publication_date: [
    {
      type: 'required',
      message: 'Publication date is required.',
    }
  ],
  link: [
    {
      type: 'required',
      message: 'Link is required.',
    },
    {
      type: 'link',
      message: 'This field must be a link.',
    },
  ],
  location: [
    {
      type: 'required',
      message: 'Geographic scope is required.',
    },
  ],
  keywords: [
    {
      type: 'required',
      message: 'At least one keyword must be specified.',
    },
  ],
};

export { model, form, rules };
